import { Card } from "components/Card/Card";
import { UserWithLinkedContactRoutes } from "pages/userWithLinkedContact/routes";
import { DetailProvider } from "providers/ContractIdProvider";
import { useKeycloak } from "providers/KeycloakProvider";
import { PersistedApolloProvider } from "providers/PersistedApolloProvider";

/**
 * Returns the remaining application stack
 * and deducts which routes to expose to the user.
 */
export const NavigationStack = () => {
  const { linkedContact, impersonating } = useKeycloak();

  const NoLinkedContactStack = () => {
    return (
      <PersistedApolloProvider>
        <div className="flex justify-center items-center h-full w-full mt-5">
          <Card className="max-w-xl py-11">
            <div className="flex justify-center items-center">No linked contact</div>
          </Card>
        </div>
      </PersistedApolloProvider>
    );
  };

  const DefaultStack = () => {
    return (
      <PersistedApolloProvider>
        <DetailProvider>
          <UserWithLinkedContactRoutes />
        </DetailProvider>
      </PersistedApolloProvider>
    );
  };

  if (linkedContact) return <DefaultStack />;

  if (!linkedContact) return <NoLinkedContactStack />;

  //fallback
  return <div>View not found</div>;
};
