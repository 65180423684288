import { ReactNode } from "react";
import { ReactComponent as Arrow } from "assets/pil.svg";
import { Logo } from "components/Logo/Logo";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useNavigate } from "react-router-dom";

interface DetailsLayoutProps {
  children?: ReactNode;
  header?: string;
}
export const DetailsLayout = ({ children, header }: DetailsLayoutProps) => {
  const navigate = useNavigate();
  const { t } = useModifiedTranslation();

  return (
    <div className="mt-[calc(var(--detailsbar-height))] margin">
      <div className="w-full relative flex justify-start items-center py-4">
        <nav className="fixed top-0 left-0 h-[var(--detailsbar-height)] outline-none  w-full margin bg-capBeige z-[1000]">
          <div className="w-full h-full flex flex-col justify-start">
            <div className="w-full h-full flex justify-start items-start pt-8 pb-2 border-b border-b-capGreen">
              <div className="flex justify-start items-start gap-6 w-full">
                <div className="flex h-12 items-center gap-cap w-full">
                  <div className="flex gap-cap">
                    <Logo onClick={() => navigate(-1)} />{" "}
                    <div
                      onClick={() => navigate(-1)}
                      className="flex gap-4 items-center cursor-pointer"
                    >
                      <Arrow className="h-8 w-8 rotate-180 fill-black cursor-pointer" />
                    </div>
                  </div>
                  {header && (
                    <div className="flex-1 whitespace-nowrap overflow-hidden text-ellipsis w-full">
                      {t(header)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>
        {children}
      </div>
    </div>
  );
};
