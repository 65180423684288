import { HTMLProps } from "react";
import classNames from "classnames";

interface DividerProps extends HTMLProps<HTMLDivElement> {
  height?: string;
}
export const Divider = ({ height, ...rest }: DividerProps) => {
  return (
    <div
      style={{ height: height ? height : "1px" }}
      className={classNames("w-full bg-slate-400", rest.className)}
    ></div>
  );
};
