import { ReactNode, useEffect, useState } from "react";
import { ReactComponent as Arrow } from "assets/pil.svg";
import classNames from "classnames";
import { createPortal } from "react-dom";

interface DrawerProps {
  onClose: () => void;
  children?: ReactNode;
}
export const Drawer = ({ onClose, children }: DrawerProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const handleClose = () => {
    document.body.style.overflowY = "auto";
    setIsLoaded(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    const closings = () => {
      setIsLoaded(false);
      setTimeout(() => {
        onClose();
      }, 300);
    };
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        closings();
      }
    };
    setIsLoaded(true);
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      document.body.style.overflowY = "auto";
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  const root = document.getElementById("root");

  return (
    root &&
    createPortal(
      <div
        className={classNames(
          "z-[99999] fixed h-full top-0 left-0 w-full bg-white transition-transform duration-300 pb-10",
          isLoaded ? "translate-x-none" : "translate-x-full"
        )}
      >
        <div className="px-8 py-4 flex h-full flex-col justify-start">
          <div className="flex justify-between w-full pb-4">
            <Arrow onClick={handleClose} className="h-8 w-8 fill-black cursor-pointer" />
          </div>
          {children}
        </div>
      </div>,
      root
    )
  );
};
