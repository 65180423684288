import { useEffect, useRef, useState } from "react";
import { ReactComponent as Spinner } from "assets/spinner.svg";
import classNames from "classnames";
import { createPortal } from "react-dom";
import { FaChevronDown } from "react-icons/fa";

interface Item {
  label: string;
  shortName: string;
  id: string | number;
}

interface SelectProps<T> {
  items: T[];
  selectedItem?: T;
  onChange: (item: T) => void;
  loading?: boolean;
}

export const Select = <T extends Item>({
  items,
  selectedItem,
  onChange,
  loading,
}: SelectProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenClose = () => {
    if (items.length === 1) {
      setIsOpen(false);
      return;
    }
    setIsOpen((prev) => {
      return !prev;
    });
  };

  if (!loading && (!items || items.length === 0)) {
    return <></>;
  }

  return (
    <div
      tabIndex={0}
      style={{ zIndex: 99999 }}
      id="asdf"
      onClick={handleOpenClose}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault();
          handleOpenClose();
        }
      }}
      className={classNames(
        "flex justify-between items-center gap-8 bg-white py-2 px-3 select-none cursor-pointer min-w-[16rem] max-w-[16rem] sm:max-w-[20rem]  g:max-w-[24rem] focus:outline-2 focus:outline-offset-2 focus:outline-capGreen",
        { "rounded-lg": !isOpen, "rounded-t-lg": isOpen },
        { "cursor-auto": items.length === 1 }
      )}
    >
      {loading ? (
        <div className="w-full h-full flex justify-center items-center">
          <Spinner className="text-capBeige animate-spin fill-capGreen w-5 h-5" />
        </div>
      ) : (
        <div className="font-bold z-[1000] whitespace-nowrap overflow-hidden overflow-ellipsis">
          {selectedItem?.label}
        </div>
      )}

      {items.length > 1 && (
        <FaChevronDown
          className={classNames("z-[1000]", {
            "rotate-180 transition-transform duration-200": isOpen,
            "rotate-0 transition-transform duration-200": !isOpen,
          })}
        />
      )}
      {isOpen && (
        <SelectArea
          selectedItem={selectedItem}
          onClose={handleOpenClose}
          onChange={onChange}
          items={items}
        />
      )}
    </div>
  );
};

interface SelectAreaProps<T> {
  items: T[];
  onChange: (item: T) => void;
  onClose: () => void;
  selectedItem: T | undefined;
}
const SelectArea = <T extends Item>({
  items,
  onChange,
  onClose,
  selectedItem,
}: SelectAreaProps<T>) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const areaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    setIsLoaded(true);
    areaRef.current?.focus();
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  const select = document.getElementById("asdf");
  const rect = select?.getBoundingClientRect();

  return (
    <div
      onBlur={() => onClose()}
      ref={areaRef}
      tabIndex={0}
      style={{
        top: 0,
        left: 0,
        zIndex: 90,
        width: rect?.width,
        position: "absolute",
        transform: `translate(${rect?.left}px,${(rect?.bottom ?? 0) - 20}px)`,
      }}
      className={classNames(
        "bg-white outline-none rounded-b-xl transition-all duration-500 overflow-hidden ",
        {
          "max-h-0": !isLoaded,
          "max-h-[40rem]": isLoaded,
        }
      )}
    >
      <div className="flex flex-col divide-y" style={{ paddingTop: "25px" }}>
        {items.map((x, i) => {
          return (
            <div
              onClick={() => onChange(x)}
              key={i}
              className={classNames("px-3 py-2 flex flex-col hover:bg-slate-200 hover:rounded-md", {
                "font-bold": x.id === selectedItem?.id,
              })}
            >
              {x.label}
              {x.shortName && <div className="text-[0.7rem]">{x.shortName}</div>}
            </div>
          );
        })}
      </div>
    </div>
  );
};
