import { Suspense, lazy } from "react";
import { LoadingIndicator } from "components";
import { PortfolioGuard } from "components/PortfolioGuard/PortfolioGuard";
import { DetailsLayout } from "layout/DetailsLayout";
import { MainLayout } from "layout/MainLayout";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

const Overview = lazy(() =>
  import("./overview").then((module) => ({ default: module.OverviewPage }))
);
const PortfolioOverview = lazy(() =>
  import("./portfolio/overview").then((module) => ({
    default: module.OverviewPage,
  }))
);
const Holdings = lazy(() =>
  import("./holdings").then((module) => ({
    default: module.HoldingsPage,
  }))
);
const PortfolioHoldings = lazy(() =>
  import("./portfolio/holdings").then((module) => ({
    default: module.PortfolioHoldingsPage,
  }))
);
const Transactions = lazy(() =>
  import("./transactions").then((module) => ({
    default: module.TransactionsPage,
  }))
);
const PortfolioTransactions = lazy(() =>
  import("./portfolio/transactions").then((module) => ({
    default: module.TransactionsPage,
  }))
);
const Orders = lazy(() =>
  import("./orders").then((module) => ({
    default: module.OrdersPage,
  }))
);
const PortfolioOrders = lazy(() =>
  import("./portfolio/orders").then((module) => ({
    default: module.OrdersPage,
  }))
);
const Contact = lazy(() => import("./contact").then((module) => ({ default: module.ContactPage })));
const Documents = lazy(() =>
  import("./documents").then((module) => ({ default: module.DocumentsPage }))
);
const Kundeprofil = lazy(() =>
  import("./kundeprofil").then((module) => ({
    default: module.KundeprofilPage,
  }))
);
const TransactionDetails = lazy(() =>
  import("./[transactionId]").then((module) => ({
    default: module.TransactionDetailspage,
  }))
);
const OrderDetails = lazy(() =>
  import("./[orderId]").then((module) => ({
    default: module.OrderDetailsPage,
  }))
);
const HoldingsDetails = lazy(() =>
  import("./[holdingId]").then((module) => ({
    default: module.HoldingDetailsPage,
  }))
);
const PortfolioHoldingsDetails = lazy(() =>
  import("./portfolio/[holdingId]").then((module) => ({
    default: module.HoldingDetailsPage,
  }))
);

const Form = lazy(() => import("./form").then((module) => ({ default: module.FormPage })));

export const UserWithLinkedContactRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route path="" element={<Navigate to="overview" replace />} />
        <Route
          path="overview"
          element={
            <PortfolioGuard>
              <Overview />
            </PortfolioGuard>
          }
        />
        <Route
          path="holdings"
          element={
            <PortfolioGuard>
              <Holdings />
            </PortfolioGuard>
          }
        />
        <Route
          path="transactions"
          element={
            <PortfolioGuard>
              <Transactions />
            </PortfolioGuard>
          }
        />
        <Route path="contact" element={<Contact />} />
        <Route path="documents" element={<Documents />} />
        <Route
          path="orders"
          element={
            <PortfolioGuard>
              <Orders />
            </PortfolioGuard>
          }
        />
        <Route path="profile" element={<Kundeprofil />} />
        <Route path="*" element={<div>404 Not Found</div>} />
      </Route>
      <Route
        path="form/:formKey"
        element={
          <Suspense fallback={<MainLayout />}>
            <Form />
          </Suspense>
        }
      />
      <Route
        path="holdings/*"
        element={
          <Suspense fallback={<MainLayout />}>
            <Outlet />
          </Suspense>
        }
      >
        <Route
          path=":holdingId"
          element={
            <PortfolioGuard>
              <HoldingsDetails />
            </PortfolioGuard>
          }
        />
      </Route>
      <Route
        path="transactions/*"
        element={
          <Suspense fallback={<MainLayout />}>
            <Outlet />
          </Suspense>
        }
      >
        <Route
          path=":transactionId"
          element={
            <PortfolioGuard>
              <TransactionDetails />
            </PortfolioGuard>
          }
        />
      </Route>

      <Route
        path="orders/*"
        element={
          <Suspense
            fallback={
              <DetailsLayout>
                <LoadingIndicator center />
              </DetailsLayout>
            }
          >
            <Outlet />
          </Suspense>
        }
      >
        <Route
          path=":orderId"
          element={
            <PortfolioGuard>
              <OrderDetails />
            </PortfolioGuard>
          }
        />
      </Route>

      <Route path="/portfolio/:portfolioId/*">
        <Route path="" element={<Navigate to="overview" replace />} />

        <Route path="*" element={<MainLayout />}>
          <Route path="overview" element={<PortfolioOverview />} />
          <Route path="holdings" element={<PortfolioHoldings />} />
          <Route path="transactions" element={<PortfolioTransactions />} />
          <Route path="contact" element={<Contact />} />
          <Route path="documents" element={<Documents />} />
          <Route path="orders" element={<PortfolioOrders />} />
          <Route path="profile" element={<Kundeprofil />} />
          <Route path="*" element={<div>404 Not Found</div>} />
        </Route>
        <Route
          path="holdings/*"
          element={
            <Suspense fallback={<MainLayout />}>
              <Outlet />
            </Suspense>
          }
        >
          <Route path=":holdingId" element={<PortfolioHoldingsDetails />} />
        </Route>
        <Route
          path="transactions/*"
          element={
            <Suspense fallback={<MainLayout />}>
              <Outlet />
            </Suspense>
          }
        >
          <Route path=":transactionId" element={<TransactionDetails />} />
        </Route>
        <Route
          path="orders/*"
          element={
            <Suspense fallback={<MainLayout />}>
              <Outlet />
            </Suspense>
          }
        >
          <Route path=":orderId" element={<TransactionDetails />} />
        </Route>
      </Route>
    </Routes>
  );
};
