import { ReactNode } from "react";
import { ReactComponent as TimesCircle } from "assets/times-circle.svg";

interface EmptyComponentProps {
  header: string;
  children?: ReactNode;
}

export const ErrorMessage = ({ header, children }: EmptyComponentProps) => (
  <div>
    <div className="grid gap-4 justify-center justify-items-center items-center text-base text-gray-500 py-[10%]">
      <TimesCircle className="w-16 h-16 " />
      <div className="text-xl md:text-2xl text-center whitespace-pre-line">{header}</div>
      <div className="px-2 text-sm md:text-base text-center whitespace-pre-line">{children}</div>
    </div>
  </div>
);
