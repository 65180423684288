import { gql, useQuery } from "@apollo/client";
import { getFetchPolicyOptions } from "api/utils";

const LAST_LOGINS_QUERY = gql`
  {
    getLastLogins
  }
`;

interface LastLoginsQuery {
  getLastLogins: number[];
}

export const useGetLastLogins = () => {
  const { loading, error, data } = useQuery<LastLoginsQuery>(LAST_LOGINS_QUERY, {
    ...getFetchPolicyOptions(`useGetLastLogins`),
  });

  return {
    loading,
    error,
    data: data && data.getLastLogins,
  };
};
