import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { useGetLastLogins } from "api/overview/useGetLastLogin";
import { LoadingIndicator } from "components";
import { useFeedI18nextWithLocale } from "hooks/useFeedI18nextWithLocale";
import { useKeycloak } from "./KeycloakProvider";

export type SelectedContact = {
  id: string | number | undefined;
  contactId: string | number | undefined;
  userName: string | undefined;
};

type ContextProps = {
  lastLogin: number | undefined;
  selectedContactId: string | number | undefined;
  selectedContact: SelectedContact | undefined;
  setLastLogin: Dispatch<SetStateAction<number | undefined>>;
  setSelectedContactId: Dispatch<SetStateAction<string | number | undefined>>;
  setSelectedContact: Dispatch<SetStateAction<SelectedContact | undefined>>;
};

const ContractIdContext = createContext<ContextProps | undefined>(undefined);

/**
 * Exposes the data of the currently selected contact
 * and allows its children to update it and subscribe
 * to changes. Selected contact is by default undefined
 * and is expected to be set by one of its children
 * (as is done by the ContactGuard component).
 */
export const DetailProvider = ({ children }: { children: ReactNode }) => {
  const [selectedContactId, setSelectedContactId] = useState<string | number | undefined>();
  const [selectedContact, setSelectedContact] = useState<SelectedContact | undefined>();
  const [lastLogin, setLastLogin] = useState<number | undefined>();
  const { linkedContact } = useKeycloak();
  const { userProfile } = useKeycloak();
  const userIsInternal = userProfile?.username?.toLowerCase()?.startsWith("inv") || false;
  const { data: contactData } = useGetContactInfo(false, linkedContact, !userIsInternal);
  const { data: lastLogins } = useGetLastLogins();

  useEffect(() => {
    linkedContact && setSelectedContactId(linkedContact);
    linkedContact && lastLogins && setLastLogin(lastLogins[0]);
    linkedContact &&
      setSelectedContact({
        id: linkedContact,
        contactId: contactData?._contactId ?? "",
        userName: contactData?.name ?? "",
      });
  }, [linkedContact, contactData?._contactId, contactData?.name, lastLogins]);

  const { isReady: isLanguageReady } = useFeedI18nextWithLocale(contactData?.locale);

  if (!isLanguageReady)
    return (
      <div className="flex justify-center items-center w-screen h-screen">
        <LoadingIndicator />
      </div>
    );

  return (
    <ContractIdContext.Provider
      value={{
        lastLogin,
        setLastLogin,
        selectedContactId,
        selectedContact,
        setSelectedContactId,
        setSelectedContact,
      }}
    >
      {children}
    </ContractIdContext.Provider>
  );
};

export const useGetContractIdData = () => {
  const state = useContext(ContractIdContext);
  if (!state) throw new Error(" data not found");

  return state;
};
