import { ChangeEvent, ReactNode, useEffect, useRef, useState } from "react";
import { Representee, useGetContactInfo } from "api/initial/useGetContactInfo";
import { useGetRepresentees } from "api/initial/useGetRepresentees";
import { ReactComponent as Hamburger } from "assets/hamburger.svg";
import { ReactComponent as LgoOut } from "assets/logg_ut.svg";
import { ReactComponent as Person } from "assets/profil_ikon.svg";
import classNames from "classnames";
import { LoadingIndicator } from "components";
import { Divider } from "components/Divider/divider";
import { Drawer } from "components/Drawer/Drawer";
import { useMatchesBreakpoint } from "hooks/useMatchesBreakpoint";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useGetContractIdData, SelectedContact } from "providers/ContractIdProvider";
import { useKeycloak } from "providers/KeycloakProvider";
import { createPortal } from "react-dom";
import { FaChevronRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { keycloakService } from "services/keycloakService";
import { initials } from "utils/initials";
import { NavItems } from "./Navbar";

export const UserMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const id = "userMenu";
  const isMd = useMatchesBreakpoint("md");
  const { userProfile } = useKeycloak();
  const userIsInternal = userProfile?.username?.toLowerCase()?.startsWith("inv") || false;
  const { selectedContactId, selectedContact, setSelectedContactId, setSelectedContact } =
    useGetContractIdData();
  const navigate = useNavigate();
  const { data: contactData, loading } = useGetContactInfo(false, undefined, !userIsInternal);
  const { data: repData, loading: repLoading } = useGetRepresentees(userIsInternal);

  if (userIsInternal && !loading && contactData && repData && !repLoading) {
    contactData.representees = repData;
  }

  const handleChange = (contact: SelectedContact) => {
    setSelectedContactId(contact.id);
    setSelectedContact(contact);
    setIsOpen(false);
    navigate("/overview");
  };

  return (
    <div id={id} className="flex items-center gap-2">
      <div className="flex items-center">{initials(selectedContact?.userName)}</div>
      <Hamburger
        tabIndex={0}
        className="text-3xl cursor-pointer stroke-black focus:outline-2 outline-offset-2 outline-capGreen"
        onClick={() => setIsOpen(true)}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            setIsOpen(true);
          }
        }}
      />
      {isOpen &&
        (isMd ? (
          <UserMenuArea
            loading={loading || repLoading}
            onChange={handleChange}
            selectedContactId={selectedContactId}
            representees={contactData?.representees}
            contactData={{
              id: contactData?.contactId,
              contactId: contactData?._contactId,
              userName: contactData?.name,
            }}
            onClose={() => setIsOpen(false)}
            id={id}
          />
        ) : (
          <Drawer onClose={() => setIsOpen(false)}>
            <NavContent
              repContent={
                <UserMenuContent
                  loading={loading || repLoading}
                  showLogout={false}
                  repLimit={1000}
                  onChange={handleChange}
                  selectedContactId={selectedContactId}
                  contactData={{
                    id: contactData?.contactId,
                    contactId: contactData?._contactId,
                    userName: contactData?.name,
                  }}
                  representees={contactData?.representees}
                />
              }
              onClick={() => setIsOpen(false)}
            />
          </Drawer>
        ))}
    </div>
  );
};

interface NavContentProps {
  onClick?: () => void;
  repContent?: ReactNode;
}
const NavContent = ({ onClick, repContent }: NavContentProps) => {
  const { t } = useModifiedTranslation();
  const [showReps, setShowReps] = useState(false);
  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-col gap-4 text-xl">
        {showReps ? (
          <div
            onClick={() => setShowReps(false)}
            className={classNames("cursor-pointer whitespace-nowrap flex gap-2 items-center")}
          >
            <FaChevronRight className="text-sm rotate-180" /> Tilbake
          </div>
        ) : (
          <div
            onClick={() => setShowReps(true)}
            className={classNames("cursor-pointer whitespace-nowrap flex gap-2 items-center")}
          >
            Innlogget bruker <FaChevronRight className="text-sm" />
          </div>
        )}
        {showReps ? repContent : <NavItems onClick={onClick} />}

        <div
          className="cursor-pointer whitespace-nowrap flex gap-2 items-center"
          onClick={() => keycloakService.onAuthLogout()}
        >
          <div>
            <LgoOut className="w-4 h-4" />
          </div>
          <div>{t("userMenu.logout")}</div>
        </div>
      </div>
    </div>
  );
};

interface UserMenuAreaProps {
  id: string;
  onClose: () => void;
  onChange: (contact: SelectedContact) => void;
  representees?: Representee[];
  selectedContactId: string | number | undefined;
  contactData?: SelectedContact;
  loading?: boolean;
}
const UserMenuArea = ({
  id,
  onClose,
  onChange,
  contactData,
  representees,
  selectedContactId,
  loading,
}: UserMenuAreaProps) => {
  const menu = document.getElementById(id);
  const rect = menu?.getBoundingClientRect();
  const areaRef = useRef<HTMLDivElement>(null);
  const [isReady, setIsReady] = useState(false);

  const left = (rect?.left ?? 0) - 300 + (rect?.width ?? 0);

  const handleClose = () => {
    setIsLoaded(false);
    setTimeout(() => {
      onClose();
    }, 400);
  };

  const handleClickOutside = (target: Element) => {
    if (target?.id !== "representeesSearch" && !areaRef.current?.contains(target as Node)) {
      handleClose();
    }
  };
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const closings = () => {
      setIsLoaded(false);
      setTimeout(() => {
        onClose();
      }, 250);
    };
    setIsLoaded(true);
    setTimeout(() => {
      setIsReady(true);
    }, 300);
    areaRef.current?.focus();
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        closings();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  return createPortal(
    <>
      <div
        tabIndex={0}
        onBlur={(e) => {
          handleClickOutside(e.relatedTarget as Element);
        }}
        ref={areaRef}
        style={{
          transform: `translate(${left}px,${(rect?.bottom ?? 0) + 10}px)`,
          zIndex: 100001,
        }}
        className={classNames(
          "ring-0 focus:ring-0 focus:outline-none overflow-hidden outline-none fixed top-0 left-0 bg-white w-[300px] border border-capGreen rounded-xl rounded-tr-none transition-all duration-400",
          {
            "opacity-100 max-h-[40rem]": isLoaded,
            "opacity-50 max-h-0": !isLoaded,
          }
        )}
      >
        <div className="w-full h-full flex flex-col p-4 gap-4">
          <UserMenuContent
            loading={loading}
            repLimit={1000}
            onChange={onChange}
            areaRef={areaRef}
            selectedContactId={selectedContactId}
            contactData={contactData}
            representees={representees}
          />
        </div>
      </div>
    </>,
    document.getElementById("root") ?? document.body
  );
};

interface UserMenuContentProps {
  representees?: Representee[];
  selectedContactId: string | number | undefined;
  onChange: (contact: SelectedContact) => void;
  contactData?: SelectedContact;
  repLimit?: number;
  areaRef?: React.RefObject<HTMLDivElement>;
  showLogout?: boolean;
  loading?: boolean;
}
const UserMenuContent = ({
  representees,
  selectedContactId,
  onChange,
  contactData,
  repLimit = 5,
  areaRef,
  showLogout = true,
  loading,
}: UserMenuContentProps) => {
  const [inputValue, setInputValue] = useState<string>("");
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  const { t } = useModifiedTranslation();
  const filteredReps = representees
    ?.filter((x, index, self) => self.findIndex((y) => y.id === x.id) === index)
    .filter((x) => x.name.toLowerCase().includes(inputValue.toLowerCase()))
    .slice(0, repLimit);

  const isManyReps = representees === undefined || representees.length > 20;

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 150);
  }, []);

  return (
    <div className="w-full flex flex-col gap-4">
      <div
        className="flex items-center py-1 gap-4 cursor-pointer"
        onClick={() =>
          onChange({
            id: contactData?.id,
            userName: contactData?.userName,
            contactId: contactData?.contactId,
          })
        }
      >
        <div>
          <Person
            className={classNames(
              "w-4 h-4 stroke-black",
              selectedContactId?.toString() === contactData?.id?.toString()
                ? "fill-black"
                : "fill-transparent"
            )}
          />
        </div>
        <div
          className={classNames({
            "font-bold": selectedContactId?.toString() === contactData?.id?.toString(),
          })}
        >
          {contactData?.userName}
        </div>
      </div>
      <Divider />
      {representees && (
        <div className="w-full">
          <input
            className="w-full rounded-xl disabled:bg-gray-100 focus:ring-capGreen focus:border-capGreen font-semibold"
            id="representeesSearch"
            value={inputValue}
            onChange={handleInputChange}
            placeholder={t("userMenu.search")}
            tabIndex={0}
            type="text"
            onBlur={() => {
              areaRef?.current?.focus();
            }}
          ></input>
        </div>
      )}
      {(!isLoaded || loading) && isManyReps && (
        <div className="flex justify-center items-center py-6">
          <LoadingIndicator size="md" center />
        </div>
      )}
      {filteredReps && isLoaded && !loading && filteredReps.length > 0 && (
        <div className="flex flex-col gap-4 max-h-96 max-full md:max-h-72 overflow-x-auto custom-scrollbar">
          {filteredReps
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((x, i) => {
              return (
                <div
                  key={i}
                  className={classNames("flex gap-4 start items-center cursor-pointer", {
                    "font-bold": selectedContactId?.toString() === x?.id?.toString(),
                  })}
                  onClick={() =>
                    onChange({
                      id: x.id,
                      userName: x.name,
                      contactId: x.contactId,
                    })
                  }
                >
                  <div className="flex items-center">
                    <Person
                      className={classNames(
                        "w-4 h-4 stroke-black",
                        selectedContactId?.toString() === x?.id?.toString()
                          ? "fill-black"
                          : "fill-transparent"
                      )}
                    />
                  </div>
                  <div>{x.name}</div>
                </div>
              );
            })}
        </div>
      )}
      {representees && representees.length > 0 && filteredReps?.length === 0 && (
        <div className="flex items-center justify-center p-6">Ingen treff</div>
      )}
      {representees && representees.length > 0 && <Divider />}
      {showLogout && (
        <div
          className="flex justify-start gap-4 py-1 items-center cursor-pointer hover:font-bold"
          onClick={() => keycloakService.onAuthLogout()}
        >
          <div>
            <LgoOut className="w-4 h-4" />
          </div>
          <div>{t("userMenu.logout")}</div>
        </div>
      )}
    </div>
  );
};
