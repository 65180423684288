import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { Select } from "components/Select/Select";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useNavigateToPortfolioTab } from "hooks/useNaviateToPortfolioTab";
import { useGetContractIdData } from "providers/ContractIdProvider";
import { Navigate, useParams } from "react-router-dom";

export const PortfolioSelect = () => {
  const { selectedContactId } = useGetContractIdData();

  const { data, loading } = useGetContactInfo(false, selectedContactId);

  const { t } = useModifiedTranslation();
  const portfolios = data?.portfolios?.sort((a, b) => a.type.code.localeCompare(b.type.code)) || [];

  const { portfolioId: portfolioIdUrl } = useParams();
  const portfolioId = portfolioIdUrl ? parseInt(portfolioIdUrl, 10) : 0;
  const navigateToPortfolioTab = useNavigateToPortfolioTab();
  const onPortfolioChange = (pf: PortfolioOption) => {
    navigateToPortfolioTab(pf.urlPrefix);
  };
  const portfolioItems = [
    ...(portfolios.length > 1
      ? [{ id: 0, label: t("navTab.totalInvestments"), urlPrefix: "", shortName: "" }]
      : []),
    ...portfolios.map((x) => {
      return { id: x.id, label: x.name, urlPrefix: `/portfolio/${x.id}`, shortName: x.shortName };
    }),
  ];

  if (portfolioId !== 0 && !loading && !portfolios.some((x) => x.id === portfolioId)) {
    return <Navigate to="/" replace />;
  }

  return (
    <Select
      loading={loading}
      selectedItem={portfolioItems.find((x) => x.id === portfolioId)}
      items={portfolioItems}
      onChange={onPortfolioChange}
    />
  );
};

export interface PortfolioOption {
  id: number;
  urlPrefix: string;
  label: string;
  shortName: string;
}
