import { ReactNode } from "react";
import { useGetContactInfo } from "api/initial/useGetContactInfo";
import { Card } from "components/Card/Card";
import { LoadingIndicator } from "components/LoadingIndicator/LoadingIndicator";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useGetContractIdData } from "providers/ContractIdProvider";
import { Navigate } from "react-router-dom";

interface PortfolioGuardProps {
  children: ReactNode;
}
export const PortfolioGuard = ({ children }: PortfolioGuardProps) => {
  const { t } = useModifiedTranslation();
  const { selectedContactId } = useGetContractIdData();
  const { data: { portfolios } = { portfolios: [] }, loading } = useGetContactInfo(
    false,
    selectedContactId
  );

  if (loading) {
    return (
      <div className="w-full">
        <Card className="w-full">
          <div className="w-full h-72 flex justify-center items-center">
            <LoadingIndicator center />
          </div>
        </Card>
      </div>
    );
  }

  if (portfolios.length === 1) {
    return <Navigate to={`/portfolio/${portfolios[0].id}/overview`} />;
  }

  if (portfolios.length === 0) {
    return (
      <Card>
        <div className="w-full h-72 flex justify-center items-center">
          {t("messages.noPortfolios")}
        </div>
      </Card>
    );
  }

  return <>{children}</>;
};
