import { ReactNode, Suspense } from "react";
import { LoadingIndicator } from "components";
import { Outlet } from "react-router-dom";
import { Navbar } from "./Navbar";

export const MainLayout = () => {
  return (
    <div className="mt-[var(--navbar-height)] margin">
      <div className="w-full relative flex justify-center items-center pt-6 pb-10">
        <Navbar />
        <Suspense fallback={<LoadingIndicator center />}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
};
